import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';

import {toggleLang, getLang} from '../services/lang';
import Layout from '../components/Layout';
import * as TXT from '../res/strings';
import * as TXTEN from '../res/strings_en';
import { INSTAL_COCINA, INSTAL_COCINA_MOB, MAP } from '../res/images';
import WAPP_BTN from '../images/whatsapp-button.png';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }

    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
    this.updateWindowDimensions();
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
    }
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  render() {
    const largeImage = <img src={INSTAL_COCINA} alt="Quinta-Lili Amenities" style={{ width: '100%', height: 'auto' }} />
    const smallImage = <img src={INSTAL_COCINA_MOB} alt="Quinta-Lili Amenities" style={{ width: '100%', height: 'auto' }} />
    const smallWappBTN = <img src={WAPP_BTN} style={{ width: '300px', height: 'auto' }}></img>
    const largeWappBTN = <img src={WAPP_BTN}></img>
    const APP_LANG = this.state.lang;
    const WAPP_MSG_EN = "https://wa.me/5215554064759?text=I'm%20interested%20in%20QuintaLili's%20reservation,%20please%20tell%20me%20more";
    const WAPP_MSG_ES = "https://wa.me/5215554064759?text=Estoy%20interesad@%20en%20reservar%20QuintaLili,%20por%20favor%20mas%20informacion";
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <div className="section" style={{ marginTop: 100 }}>
          <Container>
            <Row>
              <Col className="text-center title">
                <h1>
                  {APP_LANG === 'EN' ? TXTEN.CONTACT.TITLE1 : TXT.CONTACT.TITLE1}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className="text-center title">
                { this.state.windowWidth >= 1023 ? largeImage : smallImage } 
              </Col>
            </Row>
            <Row>
              <Col className="text-center midTitle">
                <h1>
                  {APP_LANG === 'EN' ? TXTEN.CONTACT.SUBTITLE1 : TXT.CONTACT.SUBTITLE1}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
              <p>{APP_LANG === 'EN' ? TXTEN.CONTACT.WHATSAPP_TITLE : TXT.CONTACT.WHATSAPP_TITLE}</p>
              <a href={APP_LANG === 'EN' ? WAPP_MSG_EN : WAPP_MSG_ES} target="_blank" rel="noopener noreferrer">
                { this.state.windowWidth >= 1023 ? largeWappBTN : smallWappBTN } </a>
              <p>{TXT.CONTACT.WHATSAPP}</p>
              </Col>
            </Row>
            <br />
            <span className="separator"></span>
            <Row>
              <Col className="text-center">
              <p>EMAIL:</p>
                <p>
                  <a href={'mailto:'+TXTEN.CONTACT.EMAIL} >{APP_LANG === 'EN' ? TXTEN.CONTACT.EMAIL : TXT.CONTACT.EMAIL}</a>
                </p>
              </Col>
            </Row>
            <br />
            <span className="separator"></span>
            <Row>
              <Col className="text-center">
                <p>
                  {APP_LANG === 'EN' ? TXTEN.CONTACT.LINKS_TITLE : TXT.CONTACT.LINKS_TITLE}
                </p>
                <a href={TXT.CONTACT.LINK1} target="_blank" rel="noopener noreferrer">{TXT.CONTACT.LINK1}</a><br />
                <a href={TXT.CONTACT.LINK2} target="_blank" rel="noopener noreferrer">{TXT.CONTACT.LINK2}</a><br />
              </Col>
            </Row>
            <span className="separator_100" />
            <Row>
              <Col className="text-center">
                <a href={MAP} target="_blank" rel="noopener noreferrer">
                  <img src={MAP} width="100%"></img>
                </a>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                Lago patzcuaro # 5A, Tequesquitengo, Morelos, México
              </Col>
            </Row>
            <span className="separator_100"></span>
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/reserve">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.CTA_RESERVE : TXT.BUTTON.CTA_RESERVE}
                  </div>
                </Link>
              </Col>
            </Row>
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_BACK_HOME : TXT.BUTTON.BTN_BACK_HOME}
                  </div>
                </Link>
              </Col>
            </Row>
            <Row />
          </Container>
        </div>
      </Layout>
    );
  }

}

export default Contact;